import * as React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Button } from "components/button";
import { BsClipboard, BsCheck, BsX } from "react-icons/bs";
import { useQuery, useMutation } from "react-query";
import axios from "axios";
import DeclineModal from "./declineModal";
import { useModal } from "contexts/modalContext";
import { dollarFormatter } from "utils";
import { SContent, SFooter, SHeader, SSegment } from "styles/segment";
import { theme } from "styles/theme";
import { IoIosCheckbox } from "react-icons/io";
import { Input } from "components/input";
import { A } from "components/button";
import { SubtleA } from "components/button";
import { RiDownload2Fill, RiDownloadFill } from "react-icons/ri";
import SafeArea from "components/safearea";
import { useTranslation } from 'react-i18next';

export default function Estimate() {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.estimate.index";
  const tr = (key) => t(`${tBase}.${key}`);
  
  const { id } = useParams();
  const [selectedOptions, setSelectedOptions] = React.useState([]);

  const fetchData = useQuery(
    "estimate",
    async () => {
      return await axios
        .get(
          `
          ${process.env.REACT_APP_API}/api/v1/consumer/estimates/${id}
          `
        )
        .then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        if (data?.options?.length === 1) {
          setSelectedOptions([data.options[0].id]);
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  if (fetchData.isLoading) return <div>{tr("loading")}</div>;

  if (fetchData.isSuccess) {
    let data = fetchData.data;

    const responded = data.status.id !== 3;

    const sumTotal = data.options
      .filter(
        (option) => selectedOptions.includes(option.id) || option.selected
      )
      .reduce((prev, option) => prev + option.total, 0);

    const address = data.request || data.project;

    return (
      <SafeArea>
        <SSegment>
          <h3>{tr("Estimate")} #{data.id}</h3>
          <h1>{data.description}</h1>
          <div>
            <span className="font-bold">{tr("To")}: </span>
            <span>{data.customer.name}</span>
          </div>
          {address.address && (
            <div>
              <span className="font-bold">{tr("Address")}: </span>
              <span>
                {`${address.address.address}, ${address.address.city}, ${address.address.state} ${address.address.zipcode}`}
              </span>
            </div>
          )}

          <SubtleA
            primary
            target="_blank"
            style={{ alignSelf: "flex-start", padding: "0" }}
            to={{ pathname: data.pdf }}
          >
            <RiDownloadFill />
            {tr("Download PDF")}
          </SubtleA>
        </SSegment>

        {responded ? (
          <EstimateResponded {...{ data, sumTotal }} />
        ) : (
          <EstimateNotResponded
            {...{ sumTotal, id, data, setSelectedOptions, selectedOptions }}
          />
        )}
      </SafeArea>
    );
  }
}

const Total = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  > span {
    font-size: 1.1rem;
    font-weight: bold;
  }
`;

const Item = styled.div`
  display: flex;
  padding: 1rem 0;
  border-bottom: 1px solid white;
  > div:first-child {
    flex: 1;
    display: flex;
    flex-direction: column;
    > span {
      &:first-child {
        font-weight: bold;
        font-size: 1rem;
        line-height: 1;
      }
      &:last-child {
        font-size: 0.8rem;
      }
    }
  }
  > span {
    font-weight: bold;
  }
`;

const Con = styled.div`
  width: calc(100% - 2rem);
  max-width: 600px;
  align-self: center;
  padding: 0.5rem;
  > h3 {
    padding: 0;
    margin: 0;
  }
  > h1 {
    padding: 0;
    margin: 0;
  }
  /* > div {
    background: #f0f2f8;
    margin: 2rem 0;
    box-shadow: 0 0 10px -5px grey;
    padding: 1rem;
    border-radius: 5px;
    > h2,
    h1 {
      margin: 0;
      padding: 0;
    }
  } */
`;

const EstimateNotResponded = ({
  data,
  id,
  sumTotal,
  selectedOptions,
  setSelectedOptions,
}) => {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.estimate.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const { setShow } = useModal();
  const [accepted, setAccepted] = React.useState(false);
  const [signature, setSignature] = React.useState("");
  const [checklists, setChecklists] = React.useState(null);

  React.useState(() => {
    if (data.checklists) {
      setChecklists(
        data.checklists.map((checklist) => ({
          ...checklist,
          status: null,
        }))
      );
    }
  }, [data]);

  const submitResponse = useMutation(
    async (body) =>
      await axios.post(
        `
    ${process.env.REACT_APP_API}/api/v1/consumer/estimates/${id}
    `,
        body
      ),
    {
      onSuccess: () => window.location.reload(),
    }
  );

  const handleClick = (id) => {
    setSelectedOptions((options) => {
      let temp = [...options];
      let index = options.findIndex((oid) => oid === id);
      if (index !== -1) {
        temp.splice(index, 1);
      } else {
        temp.push(id);
      }
      return temp;
    });
  };

  const populateChecklists = checklists?.map((checklist) => {
    return (
      <div key={checklist.id}>
        <p>{checklist.label}</p>
        <div className="space-x-4">
          <label>
            <input
              type="radio"
              name={"checklist-" + checklist.id}
              checked={checklist.status}
              onClick={() =>
                setChecklists((checklists) => {
                  let temp = [...checklists];
                  let idx = temp.findIndex((tmp) => tmp.id === checklist.id);

                  temp[idx].status = true;
                  return temp;
                })
              }
            />
            {tr("Yes")}
          </label>
          <label>
            <input
              type="radio"
              name={"checklist-" + checklist.id}
              checked={checklist.status === false}
              onClick={() =>
                setChecklists((checklists) => {
                  let temp = [...checklists];
                  let idx = temp.findIndex((tmp) => tmp.id === checklist.id);

                  temp[idx].status = false;
                  return temp;
                })
              }
            />
            No
          </label>
        </div>
      </div>
    );
  });

  return (
    <>
      <SummaryContainer
        data={data}
        handleClick={handleClick}
        selectedOptions={selectedOptions}
      />
      <SSegment>
        <SHeader>
          <h2>{tr("Summary")}</h2>
        </SHeader>
        <SContent className="space-y-4">
          <h1>{dollarFormatter(sumTotal)}</h1>
          {data?.customdoc?.terms_conditions && (
            <div>
              <h2>{tr("Terms And Conditions")}</h2>
              <div>
                {data?.customdoc?.terms_conditions
                  ?.split("\n")
                  ?.map((sec, i) => (
                    <p className="mb-2" key={i}>
                      {sec}
                    </p>
                  )) || ""}
              </div>
            </div>
          )}
          {data?.checklists && (
            <div>
              <h3>{tr("Checklists")}</h3>
              {populateChecklists}
            </div>
          )}
          <div className="flex items-center space-x-2 ">
            <input
              type="checkbox"
              checked={accepted}
              onChange={(e) => setAccepted(e.target.checked)}
            />
            <label style={{ fontWeight: "bold" }}>
              {tr("By accepting this bid the contract for work is awarded to")}{" "}
              {data.name}.
            </label>
          </div>

          <div>
            <Input
              placeholder={tr("Your Name")}
              label={tr("Signature")}
              value={signature}
              onChange={(e) => setSignature(e.target.value)}
            />
          </div>
        </SContent>
        <SFooter>
          <Button
            onClick={() =>
              setShow({
                label: "",
                component: (
                  <DeclineModal
                    handleSubmit={(note) =>
                      submitResponse.mutate({ status: "decline", note })
                    }
                  />
                ),
              })
            }
            style={{ width: "30%" }}
          >
            {tr("Decline")}
          </Button>
          <Button
            primary
            isLoading={submitResponse.isLoading}
            disabled={
              !signature ||
              !accepted ||
              selectedOptions.length === 0 ||
              (checklists &&
                checklists.findIndex(
                  (checklist) => checklist.status === null
                ) !== -1)
            }
            onClick={() =>
              submitResponse.mutate({
                status: "accept",
                selectedOptions,
                signature,
                checklists,
              })
            }
            style={{ width: "68%" }}
          >
            {tr("Accept")}
          </Button>
        </SFooter>
      </SSegment>
    </>
  );
};

const EstimateResponded = ({ data, sumTotal }) => {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.estimate.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const completed = data.status.id === 5;

  return (
    <>
      <EstimateRespondedCon>
        <div>
          <Clipboard />
          <StatusIcon {...{ completed }} />
        </div>

        <span>
          {tr("Estimate")}{" "}
          <span style={{ color: completed ? "green" : "red" }}>
            {completed ? tr("accepted") : tr("declined")}
          </span>
        </span>
        <p>
          {data.name} {tr("has received the estimate confirmation! For questions, please contact us at")} <a>{data.email}</a>
        </p>
      </EstimateRespondedCon>
      <SummaryContainer data={data} readOnly />
      <SSegment>
        <SHeader>
          <h2>{dollarFormatter(sumTotal)}</h2>
        </SHeader>
        <span>{data.signature}</span>
      </SSegment>
    </>
  );
};

const StatusIcon = ({ completed }) => {
  return (
    <StatusCon {...{ completed }}>
      {completed ? <BsCheck /> : <BsX />}
    </StatusCon>
  );
};

const StatusCon = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(25%, 0);

  > svg {
    background: ${(props) => (props.completed ? "green" : "red")};
    color: white;
    border-radius: 50%;
    font-size: 1.5rem;
    padding: 0.1rem;
  }
`;

const Clipboard = styled(BsClipboard)`
  font-size: 5rem;
  margin: 1rem 0;
`;

const EstimateRespondedCon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  > div {
    position: relative;
  }
  > span {
    font-weight: 500;
  }
`;

const SummaryContainer = ({ data, handleClick, selectedOptions, readOnly }) => {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.estimate.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const populateOptions = data.options?.map((option, i) => {
    const selected = selectedOptions?.includes(option.id) || option.selected;
    return (
      <Option
        key={option.id}
        showParts={
          !data?.customdoc || (data.customdoc && data.customdoc.products)
        }
        showServices={
          !data?.customdoc || (data.customdoc && data.customdoc.services)
        }
        {...{ readOnly, selected, handleClick, option, i }}
      />
    );
  });

  const optionsSelected = data.options?.filter(
    (option) => option.selected
  ).length;
  return (
    <SSegment>
      <SHeader>
        <h2>{tr("Options")}</h2>
        <SId>{selectedOptions?.length || optionsSelected} {tr("selected")}</SId>
      </SHeader>
      {populateOptions}
    </SSegment>
  );
};

const SOption = styled.div`
  padding: 0.5rem;
  border-radius: 4px;
  position: relative;
  border: ${(props) =>
    props.selected
      ? `1px solid ${theme.color.main}`
      : `1px solid ${theme.border.light}`};
  margin-bottom: 1rem;
  cursor: ${(props) => !props.readOnly && "pointer"};
  /* transition: 0.2s linear all; */
  display: flex;
  flex-direction: column;
`;

const SId = styled.span`
  font-size: 0.8rem;
  font-weight: bold;
  color: ${theme.color.subtext3};
`;

const SLabel = styled.span`
  font-weight: 500;
`;

const SDollar = styled.span`
  font-weight: bold;
  font-size: 1.2rem;
`;
const SSeeMore = styled.span`
  color: ${theme.color.main};
  font-size: 0.8rem;
  text-decoration: underline;
  &:hover {
    color: black;
  }
`;

const SCheckbox = styled(IoIosCheckbox)`
  position: absolute;
  top: 0;
  right: 0;
  color: ${(props) => (props.$selected ? theme.color.main : "grey")};
  font-size: 1.5rem;
`;

const Option = ({
  option,
  handleClick,
  i,
  selected,
  readOnly,
  showParts,
  showServices,
}) => {
  const { t } = useTranslation();
  const tBase = "pages.unprotected.estimate.index";
  const tr = (key) => t(`${tBase}.${key}`);

  const [seeMore, setSeeMore] = React.useState(true);

  const toggleSeeMore = (e) => {
    e.stopPropagation();
    setSeeMore((s) => !s);
  };

  const populateProducts = option.products.sort((a, b) => a.ordering - b.ordering).map((product) => {
    const total = dollarFormatter(product.quantity * product.price);
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "0.25rem 0",
        }}
        key={product.id}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span style={{ fontWeight: 500 }}>{product.label}</span>
          <span className="text-sm italic">
            {product.short_description}{" "}
            {product.short_description && product.long_description ? "·" : ""}{" "}
            {product.long_description}
          </span>
          <span></span>
          <SId>
            {product.quantity} x {dollarFormatter(product.price)}
          </SId>
        </div>
        <span style={{ fontWeight: "bold" }}>{total}</span>
      </div>
    );
  });

  const populateTaxes = option.taxes?.map((tax) => {
    return <div key={tax.id}></div>;
  });

  return (
    <SOption
      readOnly={readOnly}
      onClick={() => !readOnly && handleClick(option.id)}
      selected={selected}
    >
      <SContent>
        <SId>Option #{i + 1}</SId>
        <SLabel>{option.label}</SLabel>
        <SDollar>{dollarFormatter(option.total)}</SDollar>
      </SContent>

      {seeMore ? (
        <div style={{ borderTop: `1px solid ${theme.border.light}` }}>
          <SSegment>
            {!showParts && (
              <div className="flex justify-between items-center">
                <span>{tr("Parts")}</span>
                <span className="font-bold">
                  {dollarFormatter(option.parts_total)}
                </span>
              </div>
            )}
            {!showServices && (
              <div className="flex justify-between items-center">
                <span>{tr("Services")}</span>
                <span className="font-bold">
                  {dollarFormatter(option.services_total)}
                </span>
              </div>
            )}
            {populateProducts}
          </SSegment>
          {option.taxes && <SSegment>{populateTaxes}</SSegment>}

          <SSeeMore onClick={toggleSeeMore}>{tr("Show Less")}</SSeeMore>
        </div>
      ) : (
        <SSeeMore onClick={toggleSeeMore}>{tr("Show More")}</SSeeMore>
      )}
      <SCheckbox $selected={selected} />
    </SOption>
  );
};
